import React, { PureComponent } from "react";
import styles from "./Footer.module.scss";

class Footer extends PureComponent {
  render() {
    return <div className={styles.footer}>utdanning.no</div>;
  }
}

export default Footer;
